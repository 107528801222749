@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body, html {
    background: linear-gradient( 90deg, rgba(230, 231, 232, 1) 50%, rgba(215, 217, 218, 1) 100%) !important;
}

a {
    /* font-size: 13px; */
    width: 100%;
}

img {
    width: auto;
    max-width: 100%;
}

.colorDanger {
    color: #ff0000 !important;
}

.dis_none {
    display: none !important;
}

.colorSuccess {
    color: #0b7d36;
}

.inplayclr {
    color: #0b7d36 !important;
    font-weight: bold;
}

.startinclr {
    color: #ff0000 !important;
    font-weight: bold;
}

.bg_odd {
    background: #329ae7;
    /* border: 1px solid
    rgba(224, 224, 224, 1); */
}

.bg_even {
    background: #f77791;
    /* border:1px solid
    rgba(224, 224, 224, 1); */
}

.bg_bookmark {
    background: #f1eccc !important;
}

.dis_block {
    display: block !important;
}

.bg_white {
    background: #ffffff;
}

.box_none {
    box-shadow: none !important;
}

.sitebg {
    background: #eebb86;
}

.float_left {
    float: left;
}

.float_right {
    float: right;
}

.text_left {
    text-align: left;
    margin-left: 5px;
}

.text_center {
    text-align: center !important;
}

.header-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
}

.top_nav {
    width: 100%;
    float: left;
    height: 57px;
    /* background: #006bff; */
}

.top_nav header {
    background: rgb(255, 242, 18);
    background: linear-gradient(90deg, rgba(255, 242, 18, 1) 14%, rgba(185, 213, 67, 1) 100%);
}

.top_nav .logo {
    width: 200px;
}

.top_nav img.user_icon_img {
    height: 16px;
}

.top_nav .app_bar {
    padding: 3px 0;
}

.wallet p {
    margin: 0 !important;
}

.top_nav .user_icon_img svg {
    font-size: 20px;
}

.top_nav p {
    font-size: 13px;
    margin: 4px 0 0;
    font-style: italic;
    font-weight: bold;
}

.user_icon span.MuiIconButton-label p {
    display: flex;
    align-items: center;
    /* width: 66%; */
}

.user_icon span.MuiIconButton-label p svg.MuiSvgIcon-root {
    color: #f8a700;
}

.top_nav .MuiIconButton-label {
    flex-direction: column;
}

.top_nav .wallet {
    margin-right: 16px;
}

.top_nav .wallet span {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    column-gap: 10px;
}

.top_nav .wallet img {
    width: 100%;
    max-width: 25px;
}

.top_nav .wallet_icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
}

.top_nav .marquee button {
    padding: 0;
    font-size: 13px;
}

.top_nav .marquee button img {
    width: 24px;
}

.top_nav .marquee div {
    /* border-radius: 50%; */
}

.top_nav .marquee div button:first-child, .top_nav .marquee div {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.top_nav .marquee div {
    width: 50%;
}

.top_nav .marquee div button:last-child {
    width: 100%;
    background: #f8a700;
    color: #000;
    text-transform: unset;
}

.top_nav .marquee div button:last-child, .top_nav .marquee div {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

#menu-appbar>.MuiMenu-paper {
    top: 54px !important;
    right: 0px !important;
    left: unset !important;
}

#simple-menu>.MuiMenu-paper {
    top: 117px !important;
}

/* USER MODAL */

.user_modal {
    width: 100%;
    max-width: 700px !important;
    background: #ffffff;
    padding: 13px;
    margin: 4px;
    border-radius: 5px;
}

.user_modal .MuiTextField-root {
    width: 92%;
    /* margin-bottom: 16px; */
    margin: 7px;
}

/* Main Menu */

.mainmenu>nav {
    background: #000;
    display: inline-block;
    width: 100%;
    color: #ffffff;
}

.mainmenu>nav>div {
    display: inline-block;
    width: unset;
    padding: 0 0 0 16px;
    vertical-align: text-top;
}

.mainmenu>nav>div>div {
    margin: 0;
}

.mainmenu>nav>div span {
    font-size: 12px;
    letter-spacing: 1px;
}

.xs_nav, .mobile_nav, .mobile_news {
    display: none;
}

/* .mobile_tab {
    display: none !important;
} */

/* -------DashBoard Body-------- */

.bodypart {
    margin-top: 7.7% !important;
    background: #fff !important;
    /* min-height: 100vh; */
}

.MuiGrid-spacing-xs-2>.MuiGrid-item {
    padding: 0 8px 0 !important;
}

.maintabs {
    min-height: auto !important;
    background: linear-gradient( 90deg, rgba(230, 231, 232, 1) 50%, rgba(215, 217, 218, 1) 100%) !important;
    box-shadow: none !important;
    color: #000000;
    text-transform: none !important;
}

.maintabs button {
    min-height: auto !important;
    margin: 0px 4px 0 0;
    text-transform: none;
    font-size: 11px !important;
    font-weight: bold !important;
    background: #f3f0dc;
    opacity: 1;
    margin: 0 !important;
    text-align: left;
    padding: 4px;
    width: 100%;
}

.maintabs button.mini-games-btn {
    width: 60%;
    text-align: center;
}

.mobile_tab button .MuiGrid-spacing-xs-1 .MuiGrid-item {
    padding: 0;
}

.maintabs button:last-child {
    margin-right: 0;
}

.mini-games-btn span.MuiButton-label {
    display: block;
    line-height: 16px;
}

.maintabs button.Mui-selected {
    background: #f8a700;
}

.MuiTabPanel-root {
    padding: 0 !important;
}

.multitable>div {
    border-radius: 0;
    margin-top: 4px;
}

.tbl_head th {
    padding: 6px !important;
    color: #ffffff !important;
}

.tbl_head {
    background: #006bff;
}

.tbl_head .MuiTableCell-alignRight {
    text-align: center;
}

.tbl_body th {
    padding: 4px;
    vertical-align: middle;
}

.tbl_body p {
    margin: 0;
    color: #1a1a18;
}

.table_first_row {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    font-size: 13px !important;
    line-height: 1;
    font-weight: normal !important;
    /* border-top: 1px solid; */
}

.tbl_body .MuiTableCell-root {
    height: 100%;
    padding: 6px;
    vertical-align: middle;
    text-align: center;
    width: 350px;
}

.odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n+1) {
    font-weight: bold;
    background: #fca4b7;
}

.odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n) {
    background: #7cc4f7;
    font-weight: bold;
}

/* .main_tab>span>div {
    display: none;
} */

/* Side Ads */

.mini-games-bar {
    height: 30px;
    background-color: #006bff !important;
    color: #ffffff !important;
    min-height: 10px !important;
}

.mini-games-grid .MuiAccordionDetails-root {
    padding: 10px !important;
}

.mini-games-bar svg {
    color: #ffffff;
}

.mini-games {
    max-width: 100%;
    border-radius: 0 !important;
    background-color: #221f20 !important;
}

.mini-games img {
    /* max-width: 100%;
    width: 100%; */
}

.mini-games-content {
    padding: 0 !important;
    text-align: center;
    text-transform: uppercase;
}

.mini-games-content p {
    font-size: 10px;
}

.mini-games-link {
    display: flex;
    align-items: center;
    background-color: #221f20;
    justify-content: space-between;
}

.MuiCardContent-root.mini-games-content>p {
    line-height: 0.5;
}

.mini-games-link:hover {
    text-decoration: none !important;
}

.mini-games-link p {
    margin: 0 10px 0 0;
    color: #f3e729;
    font-weight: 600;
    font-style: italic;
    font-size: 9px;
}

.mini-games-btn {
    display: flex !important;
    border-radius: 0 !important;
    font-size: 10px !important;
    width: 50%;
    background: rgb(0, 152, 218);
    background: linear-gradient(90deg, rgba(0, 152, 218, 1) 26%, rgba(62, 64, 149, 1) 100%) !important;
    /* background-color: blue !important; */
    margin: 0 0 4px 0 !important;
}

.mini-games-btn::after {
    content: " ";
    position: absolute;
    top: 15%;
    left: 100%;
    margin-top: -5px;
    border-width: 14.5px;
    border-style: solid;
    border-color: transparent transparent transparent #ed1b23;
}

/* Side Bar Listing */

.side_list {
    background: linear-gradient( 166deg, #099a44, #aac921);
    background-size: 10.35px 10px;
    height: 100%;
    padding: 0 !important;
}

.side_list .MuiButtonBase-root {
    padding-top: 2px;
    padding-bottom: 2px;
    border-top: 1px solid;
}

.side_list .MuiButtonBase-root.active {
    background: linear-gradient(90deg, #f8a700 0, rgba(255, 255, 255, 0) 100%);
}

.side_list .MuiTypography-root {
    font-size: 14px;
}

/* Bottom Navigation */

.bottom_nav {
    background: rgb(255, 242, 18);
    background: linear-gradient(90deg, rgba(255, 242, 18, 1) 14%, rgba(185, 213, 67, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#322a3e", endColorstr="#1e0b38", GradientType=1);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: none;
    box-shadow: 0 -10px 10px rgb(0 0 0 / 30%);
    z-index: 2;
}

.bottom_nav>div {
    background: transparent;
}

.bottom_nav button {
    max-width: unset;
    font-weight: 900;
    background: transparent;
    color: #000;
    font-style: italic;
}

.bottom_nav button img {
    width: 25px;
}

.bottom_nav .MuiBottomNavigationAction-label {
    margin-top: 2px;
}

.bottom_nav .MuiBottomNavigationAction-root.Mui-selected {
    /* background-image: radial-gradient(circle, #ffffcc 21%, #ffff39 80%); */
    color: #000000;
}

/* ----------------DRAWER----------- */

.drawer-icon {
    max-width: 25px;
}

.drawer-icon-sports-section {
    text-align: center;
}

.drawer-icon-sports-section img {
    max-width: 50px;
}

.drawer-icon-sports-block {
    border: 1px solid #edeeef;
    margin-bottom: -1px !important;
    border-right: 0;
    padding: 10px;
}

.drawer-heading {
    width: 100%;
    border-radius: 0 !important;
    justify-content: start !important;
    font-weight: bolder;
}

.drawer-icon-sports-section p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
}

.drawer-listing .MuiListItemIcon-root {
    min-width: 36px;
}

.side-bar-admin ul .MuiButtonBase-root {
    background: #f9f4a3;
    height: 32px;
}

.side-bar-admin ul ul .MuiButtonBase-root {
    background: #f8f185 !important;
    height: 32px;
}

ul ul .MuiButtonBase-root span {
    font-size: 13px !important;
    font-weight: 600 !important;
}

.side-bar-admin .MuiDivider-root {
    background-color: #000 !important;
}

.drawer-listing span {
    font-size: 12px;
    font-weight: 600;
}

.drawer-icon-play-section {
    background-image: linear-gradient(to right, #ffff9c, #f59800, #f8a700, #fab700, #fbc600, #fbc600, #fbc600, #fbc600, #fab700, #f8a700, #f59800, #ffff9c);
    padding: 5px;
}

.drawer-icon-play-block {
    background: #060009;
    color: #ffffff;
    margin: 3px;
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 40%);
}

.drawer-icon-play-block p {
    margin: 0;
    font-weight: 300;
}

.drawer-icon-play-block p:first-child {
    color: yellow;
    background: linear-gradient(to right, #ffff9c, #f59800, #f8a700, #fab700, #fbc600, #fbc600, #fbc600, #fbc600, #fab700, #f8a700, #f59800, #ffff9c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900;
}

.hamburgerIcon svg {
    color: #0026ff;
    /* transform: rotate(5deg); */
}

.hamburgerMenu {
    display: none !important;
}

/* EVENT PAGE */

.betTab>header {
    background: #ffff9c;
    /* opacity: 0.7; */
}

.betTab .MuiTabs-root {
    min-height: auto;
}

.betTab>header {
    border-radius: 8px;
}

.betTab button {
    font-size: 14px;
    min-height: auto;
    padding: 5px 5px;
    text-transform: none;
    color: #000000;
    background: #f8a700;
    font-weight: bold;
}

.betTab .tabBlock {
    background: #ffffff;
}

.betTab .MuiBox-root {
    padding: 0;
}

.betTab .MuiBox-root thead {
    background: #bfbfbf;
}

.betTab .MuiBox-root th {
    font-weight: bold;
}

.betTab .MuiBox-root th, .betTab .MuiBox-root td {
    padding: 6px 16px;
    font-size: 13px;
}

/* POPUP */

.xs_modal {
    background: #ffffff;
    width: 98%;
    max-width: 400px;
}

.modal_btn {
    text-align: center;
}

.modal_btn button {
    margin-right: 4px !important;
    margin-left: 8px !important;
}

.modal_number {
    text-align: center;
}

.modal_number button {
    margin: 4px !important;
    border-radius: 26px;
    background: #1a1a18 !important;
    color: #ffffff;
    box-shadow: 0 0 1px;
    max-width: 50px;
}

.xs_modal .modal_head {
    background: #ffff9c !important;
    padding: 0 8px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.xs_modal .modal_head p {
    margin: 12px 0;
}

.xs_modal .top_head {
    text-align: center;
    background-image: linear-gradient(to right, #3e3358, #423c51, #333333, #423c51, #3e3358) !important;
    padding: 4px;
}

.xs_modal .top_head button {
    background: #26221c;
    font-size: 10px;
    padding: 2px 4px;
    font-weight: bold;
    border-radius: 50px !important;
    color: #fbc600;
    border: 1px solid #fbc600
}

.xs_modal .modal_body {
    padding: 12px 8px;
    background: #26221c;
}

.xs_modal .modal_body input {
    color: #ffffff;
}

.xs_modal .modal_body label {
    color: #ffffff;
}

.xs_modal .modal_body .MuiOutlinedInput-notchedOutline {
    border-color: #999999;
}

.xs_modal .modal_footer {
    background-image: linear-gradient(to right, #3e3358, #423c51, #333333, #423c51, #3e3358) !important;
    padding: 8px;
}

.xs_modal .modal_footer .modal_btn button {
    background-image: linear-gradient(to right, #ffff9c, #f59800, #f8a700, #fab700, #fbc600, #fbc600, #fbc600, #fbc600, #fab700, #f8a700, #f59800, #ffff9c) !important;
    font-weight: 900 !important;
}

/* / -----------------EVENT SINGLE POPUP---------------------- /  */

.event-single-popup {
    background: #f9f9f9;
}

.event-single-popup form {
    margin-top: 12px;
}

.eventSingleBtn {
    background-color: #006bff !important;
    margin: 10px 0 !important;
}

.eventSingleCancelBtn {
    background-color: #e61414 !important;
    width: 100%;
    color: #ffffff !important;
    margin: 5px 0 10px !important;
    font-weight: bold !important;
}

.eventSingleSuccessBtn {
    background-color: #0b7d36 !important;
    width: 100%;
    color: #ffffff !important;
    margin: 5px 0 10px !important;
    font-weight: bold !important;
}

.event-single-popup p {
    margin: 0;
}

.event_tbl .tblhead {
    color: #000;
}

.event_tbl .table_first_row {
    font-size: 12px !important;
}

.event_tbl .tbl_body .MuiTableCell-root {
    padding: 8px;
}

.event_tbl .tbl_body td {
    font-weight: bold;
}

.event_tbl th {
    padding: 2px 8px;
}

.eventP {
    font-size: 10px;
    font-weight: normal;
}

.modal_number {
    text-align: center;
}

.modal_number button {
    margin-right: 4px !important;
    margin-left: 8px !important;
    background-image: linear-gradient(to right, #ffff9c, #f59800, #f8a700, #fab700, #fbc600, #fbc600, #fbc600, #fbc600, #fab700, #f8a700, #f59800, #ffff9c);
}

/* DATA TABLE */

/* / -----------------DATA TABLE---------------------- / */

.report-section .MuiDataGrid-columnHeaderWrapper {
    background: #314b5b;
    color: #ffffff;
}

.report-section .MuiDataGrid-window {
    background-color: #ffffff;
}

.report-section .MuiIconButton-label {
    color: #a0a0a0;
}

.report-section .MuiDataGrid-root .MuiDataGrid-menuIcon {
    visibility: unset;
}

.report-section .MuiDataGrid-row.Mui-odd {
    background-color: #f5f5f5;
}

.report-section .MuiDataGrid-row.Mui-even {
    background-color: #ffffff;
}

.report-section .MuiDataGrid-row:hover {
    background-color: #dbdbdb !important;
}

.report-section .MuiDataGrid-footerContainer {
    background-color: #ffffff;
}

/* ---------------- */

.datatable_heading {
    background: #000;
    color: #fff;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-top: 2%;
}

.datatable_heading select {
    padding: 6px 32px 6px 9px;
    background: #ffffff;
    font-size: 14px;
}

.datatable_sort {
    padding: 12px 0;
    display: inline-block;
    width: 100%;
}

.datatable_sort div {
    display: inline-block;
    vertical-align: middle;
}

.datatable_sort .show_data span {
    padding-right: 8px;
}

.datatable_sort .show_data span:first-child, .datatable_sort .show_data span:last-child {
    padding-top: 5px;
}

.datatable_sort .show_data .MuiFormControl-root {
    background: #fff;
}

.datatable_sort .show_data select {
    padding: 6px 32px 6px 9px;
}

.show_data {
    margin-top: 12px;
}

.search_data {
    float: right;
}

.search_data span {
    display: inline-block;
    padding-right: 8px;
}

.search_data .MuiTextField-root {
    background: #fff;
}

.search_data .MuiTextField-root .MuiInput-underline:before {
    border: 0;
}

.datatable_sort form div.MuiInput-root {
    border: 1px solid;
    border-radius: 4px;
}

/* ACCOUNT INFO */

.datatable_filter {
    padding: 0 30px 12px;
    background: #ffffff;
}

.datatable_check .MuiSvgIcon-root {
    fill: #000000;
    font-size: 20px;
}

.datatable_check .MuiCheckbox-colorPrimary {
    padding-right: 0;
}

.datatable_check .MuiTypography-body1 {
    font-size: 13px;
    font-weight: bold;
}

.search_filter {
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.search_filter>div {
    margin-right: 24px;
    width: 20%;
}

.search_filter>input {
    width: 20%;
    min-height: 30px;
    font-size: 14px;
    padding-left: 12px;
    border: 1px solid;
    border-radius: 4px;
}

/* PROFIT LOSS FILTER */

.datatable_filter.profit_filter {
    padding: 8px 24px;
}

.datatable_filter.profit_filter select {
    border: 1px solid;
    padding-left: 4px;
    border-radius: 4px;
    font-size: 14px;
}

.datatable_filter.profit_filter>div:first-child {
    width: 20%;
}

.datatable_filter.profit_filter>input {
    width: 15%;
    min-height: 30px;
    border: 1px solid;
    border-radius: 4px;
    margin-left: 16px;
    font-size: 14px;
    padding-left: 12px;
}

.datatable_filter.profit_filter>input[type="text"] {
    width: 15% !important;
}

.datatable_filter.profit_filter>button, .search_filter>button {
    font-size: 12px;
    margin-left: 12px;
    background-image: linear-gradient(to right, #ffff9c, #f59800, #f8a700, #fab700, #fbc600, #fbc600, #fbc600, #fbc600, #fab700, #f8a700, #f59800, #ffff9c);
    color: #000;
    font-weight: 600;
}

/* BET HISTORY */

.datatable_filter.bethistory input {
    margin-left: 0;
    margin-right: 14px;
    border-color: #aaa;
}

.datatable_filter.bethistory>input {
    width: 12% !important;
}

.datatable_filter.bethistory>input[type="text"] {
    width: 8% !important;
}

.datatable_filter.bethistory>div {
    width: 15% !important;
    margin-right: 14px;
}

.datatable_filter.bethistory select {
    padding-left: 12px;
    border-color: #aaa;
}

.datatable_filter.livebethistory input {
    margin-left: 0;
    margin-right: 14px;
    border-color: #aaa;
}

.datatable_filter.livebethistory>input {
    width: 12% !important;
}

.datatable_filter.livebethistory>div {
    width: 15% !important;
    margin-right: 14px;
}

.datatable_filter.livebethistory select {
    padding-left: 12px;
    border-color: #aaa;
}

.datatable_filter.livebethistory>P {
    margin: 0;
    font-size: 12px;
}

/* ------------------------ */

.mheaderm>p {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.mobile_news .news-right-arrow {
    position: absolute;
    font-size: 30px;
    right: -10px;
    font-weight: 100;
}

.mobile-card-tabs .mobile_tab>div>div>button {
    display: block;
    background: transparent;
    padding: 0;
    margin: 0;
}

.mobile-card-tabs .mobile_tab>div>div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0fr 0fr;
    grid-column-gap: 6px;
    column-gap: 6px;
    grid-row-gap: 6px;
    row-gap: 6px;
    background: linear-gradient( 90deg, rgba(230, 231, 232, 1) 50%, rgba(215, 217, 218, 1) 100%) !important;
}

.mobile-card-tabs {
    padding-top: 3px;
    background: rgb(230, 231, 232);
    background: linear-gradient(90deg, rgba(230, 231, 232, 1) 50%, rgba(215, 217, 218, 1) 100%) !important;
}

.MuiGrid-root.bodypart.custom-container.grid-row {
    display: grid;
    grid-template-columns: 200px auto;
    grid-column-gap: 10px;
    column-gap: 10px;
}

.MuiGrid-root.bodypart.custom-container.grid-row>div {
    width: 100%;
    max-width: 100%;
}

.mini-games img {
    width: 100%;
    display: block;
}

button.desktop-news span {
    position: relative;
}

.marquee {
    overflow: hidden;
}

.marquee button.desktop-news span.MuiButton-label:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 13px solid white;
    position: absolute;
    right: -13px;
    z-index: 1;
}

.event-single-popup {
    padding: 10px;
}

.app_bar>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 10px;
    column-gap: 10px;
}

button.user_icon {
    padding: 0;
}

.inplay {
    font-weight: bold;
    color: #007534 !important;
    position: relative;
    font-size: 12px;
}

.inplay::before {
    content: "";
    width: 20px;
    height: 3px;
    background: #007534;
    position: absolute;
    bottom: -5px;
}

.going-to-play {
    color: #7a0e00 !important;
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
}

.text_right {
    display: flex;
    grid-column-gap: 5px;
    column-gap: 5px;
    align-items: baseline;
}

.text_right {
    display: flex;
    grid-column-gap: 5px;
    column-gap: 5px;
    align-items: baseline;
}

.text_right span {
    line-height: initial;
    font-size: 12px;
}

.going-to-play {
    min-width: 50px !important;
    display: block;
}

.darktblhead {
    background: #314b5b;
}

.darktblhead th {
    color: #fff;
    font-weight: bold;
    padding: 1px 3px !important
}

tr.MuiTableRow-root.yelloBG {
    background-image: linear-gradient(to right, #ffff9c, #f59800, #f8a700, #fab700, #fbc600, #fbc600, #fbc600, #fbc600, #fab700, #f8a700, #f59800, #ffff9c);
}

tr.yelloBG .bg_odd, tr.yelloBG .bg_even {
    /* background: transparent; */
    color: #000;
    border: 1px solid #ddd;
}

tr .table_first_row {
    /* border-color: transparent; */
    line-height: 1.6 !important;
    min-height: 35px;
    max-height: 45px;
    overflow: hidden;
}

tr .table_first_head {
    /* border-color: transparent; */
    line-height: 1.6 !important;
    min-height: 31px !important;
    font-size: 12px;
}

.td-cell {
    /* border-radius: 55px; */
    /* border: 2px solid #fff; */
    min-width: 50px;
    padding: 2px 0;
    margin: 0px;
    /* height: 41px; */
    min-height: 33px;
    max-height: 45px;
}

.bg_odd .td-cell {
    background: #329ae7;
    padding: 5px;
}

.bg_suspended {
    background: #666666;
}

.bg_suspended .td-cell {
    color: #fff !important;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 50px;
}

.bg_even .td-cell {
    background: #f77791;
    padding: 5px;
}

.event_tbl .tbl_body .yelloBG .MuiTableCell-root {
    padding: 0;
}

.event_tbl tbody.tbl_body .MuiTableRow-root {
    position: relative;
}

.sus-parent {
    position: relative;
}

.suspended-cell {
    position: absolute;
    top: 0;
    /* left: 0; */
    width: 57%;
    height: 100%;
    background: rgb(139 161 177 / 80%);
    ;
    color: #fff;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-shadow: 0 0 1px #000;
    z-index: 1;
}

#suspended:after {
    content: "Suspended";
    position: absolute;
}

.space-l-f-10 {
    padding: 0 10px;
}

.space-col {
    justify-content: space-between;
}

.space-col .MuiGrid-grid-sm-6 {
    max-width: 48%;
}

.MuiGrid-root.flex-row {
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    column-gap: 10px;
}

.MuiTabs-indicator {
    display: none;
}

.place-bat-model {
    background: #352758;
}

.white-txt {
    color: #fff;
}

.left-txt {
    text-align: left;
}

.right-txt {
    text-align: right;
    display: block;
}

.event_image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    /* object-fit: fill; */
}

.sport-heading {
    color: #000;
    font-size: 13px;
    font-weight: 600;
    padding: 5px;
}

.sport-heading-container {
    padding: 5px 0px;
    border-bottom: 1px solid #efefef;
    /* margin-bottom: 5px; */
}

.MuiMenuItem-root {
    min-height: 25px !important;
    border-bottom: 1px solid #efefef !important;
}

.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.MuiTableCell-root {
    padding: 0px !important;
}

.subHeading {
    font-size: 14px !important;
}

.MuiOutlinedInput-root {
    /* width: 50%; */
}

.MuiCardContent-root {
    padding: 0px !important;
}

.extra-sm-btn {
    margin: 0px 3px 0px 3px;
    max-width: '30px';
    max-height: '30px';
    min-width: '30px';
    min-height: '30px';
    color: #000;
    background-color: #e2a521;
    border-color: #e2a521;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    float: right
}

.extra-sm-add-btn {
    margin: 0px 3px 0px 3px;
    max-width: '30px';
    max-height: '30px';
    min-width: '30px';
    min-height: '30px';
    color: #fff;
    background: linear-gradient( 180deg, #76c7ce 0, #12717c)!important;
    border-color: #217e89!important;
    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.for-s {
    margin: 0px 3px 0px 3px;
    max-width: '30px';
    max-height: '30px';
    min-width: '30px';
    min-height: '30px';
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 3px;
}

.MuiListItem-root {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.blue_button {
    padding: 5px 20px;
    background-image: linear-gradient(#b159c2, #853395)!important;
    color: #fff;
    font-size: 13px;
    border-radius: 2px;
    font-weight: 700;
    margin-right: 10px;
    width: 100%;
}

.event-name {
    margin-left: 5px !important;
    font-size: unset !important;
}

.countdown-head {
    font-size: 11px;
}

.countdown-foot {
    font-size: 9px;
    color: grey !important;
}

.th-mobile {
    padding: 8px 0px 8px 0px !important;
}

.btn-sm-danger {
    margin: 0px 3px 0px 3px !important;
    max-width: '30px'!important;
    max-height: '30px'!important;
    min-width: '30px'!important;
    min-height: '30px' !important;
    color: #fff !important;
    background-color: #d9534f !important;
    border-color: #d43f3a !important;
    padding: 5px 5px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
}

.btn-for-add {
    margin: 0px 3px 0px 3px !important;
    max-width: '30px'!important;
    max-height: '30px'!important;
    min-width: '30px'!important;
    min-height: '30px'!important;
    color: #fff !important;
    background: linear-gradient( 180deg, #76c7ce 0, #12717c)!important;
    border-color: #217e89!important;
    padding: 4px 5px !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
    border-radius: 3px !important;
}

.square-btn {
    flex: 1 1;
    color: white;
    background: #3a4e5a;
    text-align: center;
    margin: 0 3px;
    position: relative;
    display: block;
    padding: 11px 5px;
    width: 100%;
}

.betHistory-btns button.active>button:focus {
    background-image: linear-gradient(#c491ce, #c491ce)!important;
}

.title_new_at {
    width: 93%;
    display: inline-block;
    background: #232323;
    padding: 5px;
    color: #fff;
    margin: 8px 0px 0px 0px;
}

.MuiTableCell-sizeSmall {
    padding: 6px 24px 6px 16px !important;
}

.text_left p {
    /* margin-top: 8px; */
}

.MuiTab-wrapper {
    display: block !important;
}

.MuiList-root li a {
    font-size: 12px;
    font-weight: 600;
    width: 100%;
}

.MuiListItemText-root span {
    font-size: 15px !important;
    font-weight: 600 !important;
}

.MuiBottomNavigation-root {
    height: 45px !important;
}

.MuiTableCell-root {
    line-height: 1.3 !important;
}

.modal_btn button {
    width: 30% !important;
}

.right-txt, .bet-runner_name {
    font-size: 10px;
    text-align: center;
}

.runner-on-bet {
    font-size: 13px;
    color: white;
    width: 33%;
    text-align: center;
}

.parent-runner-on-bet {
    display: flex;
}

.side-bar-admin .MuiDrawer-paper {
    background: #f5f5f5;
}

.MuiPaper-rounded {
    border-radius: unset !important;
}

.event_name_heading {
    background: #000;
    color: #fff;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 100%;
    /* margin-top: 31%; */
}

.MuiAccordion-root.Mui-expanded {
    margin: 4px 0 !important;
    width: 100% !important;
}

#panel1d-header {
    background: #AFB763;
    /* margin: 3; */
    height: 36px;
    padding: 5px;
}

.MuiAccordionSummary-root {
    min-height: 36px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 36px !important;
    /* width: auto!important; */
}

.MuiPaper-elevation1 {
    width: 100%;
}

.accodian-active {
    /* background-color: rgba(15, 15, 15, 0.37); */
    color: rgba(255, 255, 255, 0.863);
    padding: 2px;
    border-radius: 2px;
    /* box-shadow: 60px -16px teal; */
}

#betList thead tr td {
    padding: 5px !important;
}

#betList th, td {
    padding: 6px 16px !important;
    font-size: 13px;
    width: auto;
}

#betList td {
    padding: 5px 3px !important;
    font-size: 11px;
    width: auto;
}

.report-card {
    height: 100vh;
}

.showentries {
    font-size: 13px;
    display: table-cell;
    vertical-align: middle;
}

.show_data {
    display: table;
}

.dates {
    width: 97%;
}

.gametype {
    /* min-width: 25.5vh; */
}

.splide {
    padding: 0 !important;
    /* height: 217px; */
}

.odd_even_clr td:nth-child(1) {}

.even {
    background: #7cc4f7 !important;
}

.odd {
    background: #fca4b7 !important;
}

.drawer-icons {
    /* margin-right: 5px; */
    margin: 9px 8px 9px 0;
}

.bal {
    text-align: left;
    color: #000 !important;
}

.group {
    float: bottom;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: normal;
}

.group div {
    /* float: left; */
    display: contents;
}

.group span {
    float: right;
    font-size: 12px !important;
    font-weight: normal !important;
}

.login-name {
    font-size: unset !important;
    margin: 0 10px;
    padding: 16px;
    font-weight: 700;
    border-bottom: 1px solid #f2f2f2;
    text-align: center;
    font-size: 20px !important;
    text-transform: capitalize;
}

.userdrawer .MuiDrawer-paper {
    width: 260px !important;
}

/* text-center d-block w-100 d-none-big */

.footerbig {
    text-align: center!important;
    display: block;
    width: 100%;
    margin-top: 19px;
    /* text-center d-block w-100 d-none-big */
}

.footerbig a {
    padding: 2px;
}

.indiv {
    display: inline-block;
    /* d-inline-block vm */
    vertical-align: middle;
}

.footer-logo {
    width: 10%;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
}

.footer-social {
    display: inline-block;
    justify-content: center;
    text-align: right;
    width: 89%;
}

.footer-social a {
    padding: 2px
}

.footer-social a img {
    width: 3%;
}

.social-logo {
    display: inline-flex;
}

.footer-infos {
    position: relative;
    margin-bottom: 56px;
    /* bottom: 5px; */
    /* background: white; */
    padding: 8px;
}

.footer-bottom {
    margin-top: 19px;
    line-height: 19px;
}

.footer-bottom div {
    text-align: center;
    font-size: 13px;
    font-weight: normal;
}

.slider-margin {
    margin-top: 90px !important;
}

.splide__arrows {
    display: none;
}

.splide__pagination {
    bottom: 0.2em !important;
}

.splide__pagination__page {
    width: 8px !important;
}

.report-section .MuiGrid-item {
    padding: 7px;
}

.report-section {
    /* height: 100vh; */
}

.report-section .MuiGrid-container .MuiGrid-item {
    padding: 7px;
}

.viewinfo_event_div {
    padding: 10px;
}

.MuiDataGrid-windowContainer {
    height: auto !important;
}

.MuiDataGrid-menuIcon {
    display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    flex: unset;
    padding: 0px;
}

.MuiDataGrid-main {
    overflow: scroll;
}

.user-drawer-li {
    height: 41px;
}

.MuiGrid-container {
    /* padding: 5px; */
}

.button-tv1 {
    background: #AFB763 !important;
    border-radius: 0px !important;
    margin: 4px 0px 0px 0px !important;
    width: 99.7%;
    color: #000000a3 !important;
    box-shadow: none !important;
    padding: 3px !important;
    font-size: 13px !important;
    font-weight: bold !important;
}

.button-tv {
    background: #AFB763 !important;
    border-radius: 0px !important;
    margin: 4px 0px 0px 0px !important;
    width: 100%;
    color: #000000a3 !important;
    box-shadow: none !important;
    padding: 3px !important;
    font-size: 13px !important;
    font-weight: bold !important;
}

.event_tbl table {
    margin-bottom: 3px;
}

.Toastify__close-button {
    opacity: 1 !important;
    padding: 14px !important;
}

.Toastify__toast-body {
    text-transform: capitalize;
}

#transition-modal-title {
    margin-left: 10px;
}

.block-market-name a {
    word-break: break-all;
}

.MuiDataGrid-cell {
    display: table-cell !important;
    white-space: pre-line !important;
    line-height: unset !important;
    padding-top: 6px !important;
}

.bodypart-dashboard {
    margin-top: -1px !important;
}

.opentitle {
    float: left
}

.opendate {
    float: right
}

.event-iframe {
    padding: 0px !important;
    height: 100% !important;
}

.my-accordian .MuiAccordion-root.Mui-expanded {
    margin: unset !important;
    margin: 0px !important;
}

.MuiDataGrid-windowContainer .MuiLink-root {
    font-size: 0.875rem !important;
    text-align: left;
}

.MuiDataGrid-columnHeaderWrapper {
    height: 43px !important;
}

.newss {
    margin-right: 85px;
}

.bet-slip {
    height: 500px
}

#betList {
    width: 1000px
}

p.market-exposure {
    display: block;
    font-weight: 600;
    /* float: right; */
    margin-right: 5px;
    font-size: 12px;
}

.runner-name {
    display: inline-block;
    font-weight: 600;
}

.market-exposure[data-value] {
    color: green !important;
}

.market-exposure[data-value^="-"] {
    color: Red !important;
}

.runner-on-bet[data-value] {
    color: green !important;
}

.runner-on-bet[data-value^="-"] {
    color: Red !important;
}

.text_left {
    width: 100%;
    font-size: 12px;
    font-weight: 600;
}

.showtv {
    height: 364px;
}

.fancy-position[data-value] {
    color: green !important;
}

.fancy-position[data-value^="-"] {
    color: Red !important;
}

.fancy-td-cell {
    /* border-radius: 55px; */
    /* border: 2px solid #fff; */
    min-width: 50px;
    padding: 2px 0;
    margin: 0px;
    /* height: 41px; */
    min-height: 32px;
    max-height: 45px;
}

.bg_even .fancy-td-cell {
    background: #f77791;
}

.bg_odd .fancy-td-cell {
    background: #329ae7;
}

#fancyAPI .table_first_row {
    max-width: 255px;
    padding: 6px 0px 6px 0px !important
}

.fancy_th_bg_odd {
    background: #329ae7;
    font-size: 12px !important;
}

.fancy_th_bg_even {
    background: #f77791;
    font-size: 12px !important;
}

.match_th_bg_even {
    font-size: 12px !important;
}

.table_first_row {
    max-width: 229px !important;
    padding: 4px 1px !important;
}

.opentitle {
    font-size: 12px !important;
}

.opendate {
    font-size: 12px !important;
}

.desktop-marquee {
    flex-grow: 1;
    width: 50%;
}

.loaderDiv {
    display: grid;
    place-items: center;
    margin: 100px;
}

.suspended:after {
    content: attr(data-title);
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px !important;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 455px);
    right: 0;
    background: rgb(139 161 177 / 80%);
    ;
    color: #fff;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    text-transform: uppercase;
    font-weight: 900;
    font-size: 18px;
    top: 0;
}

.Mui-disabled {
    opacity: 0.6;
}

.summary-ac-head {
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 242, 18, 1) 14%, rgba(185, 213, 67, 1) 100%) !important;
}

.summary-tblhead {
    background: #b9b9b9;
    width: 100%;
}

.summary-h-btn {
    background-color: #D3E89C !important;
    border-color: #D3E89C !important;
    margin: 2px !important;
}

.summary-s-btn {
    background-color: #FFF700 !important;
    border-color: FFF700 !important;
}

.chip-summary-tbl .MuiTableCell-sizeSmall {
    padding: 6px 4px !important;
}

.chip-summary-pl[data-value] {
    color: green !important;
}

.chip-summary-pl[data-value^="-"] {
    color: Red !important;
}

.username-container {
    display: table-cell;
}

.username-container span {
    vertical-align: middle;
}

.back-row {
    background-color: #7cc4f7 !important;
}

.lay-row {
    background-color: #fca4b7 !important;
}

.current-position[data-value] {
    color: green !important;
}

.current-position[data-value^="-"] {
    color: Red !important;
}

.pbb {
    display: none !important;
}

@keyframes typeing {
    0% {
        opacity: 0
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.game-fancy {
    display: none !important;
}

.lcolor {
    background-color: #F2E5E8 !important;
}

.bcolor {
    background-color: #dceaf4 !important;
}

.l-dark-color {
    background-color: #EBD5DB !important;
}

.b-dark-color {
    background-color: #C7DBE9 !important;
}

.going-inplay-cricle {
    color: grey !important;
    font-size: 15px !important;
    vertical-align: middle;
    margin-right: 1.33333vw;
}

.game-fancy img {
    background-color: #0A92A5 !important;
}

.disable {
    cursor: not-allowed;
    pointer-events: none;
    /*Button disabled - CSS color class*/
    color: white !important;
    /* background-color: #ffffff !important; */
    background-image: linear-gradient( -180deg, #00800094 15%, #008000b0 100%) !important;
    opacity: 0.5 !important;
}

.cancelbtn {
    background: #d90303c9 !important;
}

.book-fancy {
    float: right;
    margin: 2px;
    background: linear-gradient( 90deg, rgb(255 242 18 / 63%) 14%, rgb(185 213 67 / 45%) 100%);
    border-radius: 5px;
    border-color: rgba(185, 213, 67, 1);
}

.xs-bet-slip {
    background-color: #DCEAF4;
    /* padding-top: 2.66667vw !important; */
    border-bottom: 1px solid #7e97a7 !important;
}

.bet-list {
    /* padding: 0 1.6vw 2.66667vw; */
    display: flex;
}

.bet-list>div {
    width: 100%;
    margin: 1.6vw 0.8vw 0 0.8vw;
    display: flex;
    flex-direction: column;
}

.bet-list .typed {
    color: #999;
    background-color: #dcdcdc;
    width: 100%;
    box-shadow: none;
    border: 1px solid #bbb;
    /* font-size: 4vw; */
    /* border-radius: 1.6vw; */
}

.bet-list p {
    /* font-size: 2.93333vw; */
    text-align: center;
}

.input-num>div {
    border: 1px solid #aaa;
    border-radius: 1.6vw;
    display: flex;
}

.input-num>div a {
    height: 38px;
    /* line-height: 10.93333vw; */
    /* width: 12vw; */
    background-image: linear-gradient(-180deg, #fff 0%, #eee 89%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-num>div span {
    display: flex;
    color: #1e1e1e;
    background-color: #fff0ca;
    box-shadow: inset 0 0.26667vw 1.33333vw rgb(161 128 45 / 60%);
    border-color: #be7809;
    width: 160%;
    align-items: center;
    justify-content: center;
    /* font-size: 4vw; */
    font-weight: bold;
}

.input-num>div input {
    display: flex;
    color: #1e1e1e;
    background-color: #fff0ca;
    box-shadow: inset 0 0.26667vw 1.33333vw rgb(161 128 45 / 60%);
    border-color: #be780938;
    width: 160%;
    align-items: center;
    justify-content: center;
    /* font-size: 4vw; */
    font-weight: bold;
    text-align: center;
}

.input-num>div a svg {
    font-weight: bold;
    font-size: 28px;
    color: #1f72ac;
}

.coin-list {
    margin-bottom: 1.86667vw;
    background-image: linear-gradient(-180deg, #32617F 20%, #1F4258 91%);
    display: flex;
}

.coin-list a {
    width: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    line-height: 2.46;
    background-image: linear-gradient(-180deg, #32617F 20%, #1F4258 91%);
    color: #fff;
    text-align: center;
}

.keyboard-wrap {
    display: flex;
    border-top: 1px solid #aaa;
    /* margin-bottom: 1.86667vw; */
}

.keyboard-wrap .btn-tel {
    display: flex;
    flex: 1 1;
    flex-wrap: wrap;
}

.keyboard-wrap .btn-tel a {
    display: flex;
    flex: 1 1 15.66667%;
    /* font-size: 4vw; */
    color: #1e1e1e;
    /* line-height: 10.4vw; */
    background-color: #fff;
    border: 1px solid #aaa;
    border-width: 0 0 1px 1px;
    align-items: center;
    justify-content: center;
}

.keyboard-wrap>a {
    display: flex;
    /* flex: 0 1 14.66667vw; */
    color: #1e1e1e;
    /* line-height: 10.4vw; */
    background-color: #fff;
    border: 1px solid #aaa;
    border-width: 0 0 1px 1px;
    align-items: center;
    justify-content: center;
}

.btn-list {
    padding: 9px;
    display: flex;
    flex-wrap: wrap;
}

.btn-list a {
    flex: 1 1;
    margin: 5px;
    /* height: 10.93333vw; */
    /* line-height: 10.93333vw; */
    background-image: linear-gradient( -180deg, #fff 0%, #eee 89%);
    border: 1px solid #aaa;
    border-radius: 1.6vw;
    /* font-size: 4vw; */
    font-weight: bold;
    line-height: 2.6;
    color: #1e1e1e;
    text-align: center;
}

.btn-list-new {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.btn-list-new a {
    margin: 1.6vw 0.8vw 0 0.8vw;
    /* height: 10.93333vw; */
    /* line-height: 10.93333vw; */
    background-image: linear-gradient( -180deg, #fff 0%, #eee 89%);
    border: 1px solid #aaa;
    border-radius: 1.6vw;
    /* font-size: 4vw; */
    font-weight: bold;
    line-height: 2.6;
    color: #1e1e1e;
    text-align: center;
    width: 90%;
}

.btn-list a.placebtn {
    background-image: linear-gradient( -180deg, #008000 15%, #008000 100%);
    border-color: #008000;
    color: #000;
    display: table-cell;
}

.acceptodds {
    /* padding: 0 1.86667vw; */
    background: #C7DBE9;
    /* height: 8.53333vw; */
    display: flex;
    align-items: center;
    /* font-size: 3.46667vw; */
}

.acceptodds label {
    width: 100%;
}

.acceptodds a {
    display: flex;
    width: 100%;
    /* height: 8.53333vw; */
    align-items: center;
    /* font-size: 3.46667vw; */
    color: #1e1e1e;
    /* margin-right: 1.86667vw; */
}

.typeing:after {
    content: '';
    width: 0.66667vw;
    height: 1.01vw;
    background-color: #1F72AC;
    animation: typeing 1s infinite;
    box-shadow: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    border: 0.7px solid #aaaaaa8a !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    overflow: unset !important;
}

#settlements .MuiTableCell-sizeSmall {
    padding: 3px !important;
}

.set-loader {
    display: grid;
    place-items: center;
    padding: 10px;
    height: 72vh;
}

.no-row {
    display: grid;
    place-items: center;
    font-size: 27px;
    padding: 50px;
}

.load-logo {
    height: 65vh;
    display: grid;
    place-items: center;
    padding: 20px;
    background: #dfdfdf;
}
@media (max-width: 600px) and (min-width: 200px) {
    .typeing:after {
        content: '';
        width: 0.66667vw;
        height: 5.33333vw;
        background-color: #1F72AC;
        animation: typeing 1s infinite;
        box-shadow: none !important;
    }
    /* Bet Slip */
    .xs-bet-slip {
        background-color: #DCEAF4;
        padding-top: 2.66667vw !important;
        border-bottom: 1px solid #7e97a7 !important;
    }
    .bet-list {
        padding: 0 1.6vw 2.66667vw;
        display: flex;
    }
    .bet-list>div {
        width: 100%;
        margin: 1.6vw 0.8vw 0 0.8vw;
        display: flex;
        flex-direction: column;
    }
    .bet-list .typed {
        color: #999;
        background-color: #dcdcdc;
        width: 100%;
        box-shadow: none;
        border: 1px solid #bbb;
        font-size: 4vw;
        border-radius: 1.6vw;
    }
    .bet-list p {
        font-size: 2.93333vw;
        text-align: center;
    }
    .input-num>div {
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        display: flex;
    }
    .input-num>div a {
        height: 10.93333vw;
        line-height: 10.93333vw;
        width: 12vw;
        background-image: linear-gradient(-180deg, #fff 0%, #eee 89%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-num>div span {
        display: flex;
        color: #1e1e1e;
        background-color: #fff0ca;
        box-shadow: inset 0 0.26667vw 1.33333vw rgb(161 128 45 / 60%);
        border-color: #be7809;
        width: 50%;
        align-items: center;
        justify-content: center;
        font-size: 4vw;
        font-weight: bold;
    }
    .input-num>div input {
        display: flex;
        color: #1e1e1e;
        background-color: #fff0ca;
        box-shadow: inset 0 0.26667vw 1.33333vw rgb(161 128 45 / 60%);
        border-color: #be780938;
        width: 50%;
        align-items: center;
        justify-content: center;
        font-size: 4vw;
        font-weight: bold;
        text-align:center;
    }
    .input-num>div a svg {
        font-weight: bold;
        font-size: 28px;
        color: #1f72ac;
    }
    .coin-list {
        margin-bottom: 1.86667vw;
        background-image: linear-gradient(-180deg, #32617F 20%, #1F4258 91%);
        display: flex;
    }
    .coin-list a {
        width: 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        line-height: 2.46;
        background-image: linear-gradient(-180deg, #32617F 20%, #1F4258 91%);
        color: #fff;
        text-align: center;
    }
    .keyboard-wrap {
        display: flex;
        border-top: 1px solid #aaa;
        margin-bottom: 1.86667vw;
    }
    .keyboard-wrap .btn-tel {
        display: flex;
        flex: 1 1;
        flex-wrap: wrap;
    }
    .keyboard-wrap .btn-tel a {
        display: flex;
        flex: 1 1 15.66667%;
        font-size: 4vw;
        color: #1e1e1e;
        line-height: 10.4vw;
        background-color: #fff;
        border: 1px solid #aaa;
        border-width: 0 0 1px 1px;
        align-items: center;
        justify-content: center;
    }
    .keyboard-wrap>a {
        display: flex;
        flex: 0 1 14.66667vw;
        color: #1e1e1e;
        line-height: 10.4vw;
        background-color: #fff;
        border: 1px solid #aaa;
        border-width: 0 0 1px 1px;
        align-items: center;
        justify-content: center;
    }
    .btn-list {
        padding: 0 1.6vw 2.66667vw;
        display: flex;
        flex-wrap: wrap;
    }
    .btn-list a {
        flex: 1 1;
        margin: 1.6vw 0.8vw 0 0.8vw;
        height: 10.93333vw;
        line-height: 10.93333vw;
        background-image: linear-gradient( -180deg, #fff 0%, #eee 89%);
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        font-size: 4vw;
        font-weight: bold;
        line-height: 2.6;
        color: #1e1e1e;
        text-align: center;
    }
    .btn-list-new {
        padding: 0 1.6vw 2.66667vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    
    
    }
    .btn-list-new a {
        margin: 1.6vw 0.8vw 0 0.8vw;
        height: 10.93333vw;
        line-height: 10.93333vw;
        background-image: linear-gradient( -180deg, #fff 0%, #eee 89%);
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        font-size: 4vw;
        font-weight: bold;
        line-height: 2.6;
        color: #1e1e1e;
        text-align: center;
        width: 90%;
    }
    .btn-list a.placebtn {
        background-image: linear-gradient( -180deg, #008000 15%, #008000 100%);
        border-color: #008000;
        color: #000;
        display: table-cell;
    }
    .acceptodds {
        padding: 0 1.86667vw;
        background: #C7DBE9;
        height: 8.53333vw;
        display: flex;
        align-items: center;
        font-size: 3.46667vw;
    }
    .acceptodds label {
        width: 100%;
    }
    .acceptodds a {
        display: flex;
        width: 100%;
        height: 8.53333vw;
        align-items: center;
        font-size: 3.46667vw;
        color: #1e1e1e;
        margin-right: 1.86667vw;
    }
    .MuiTabs-flexContainer {
        padding-top: 0px;
    }
    .sport-xs-tab button.Mui-selected, .sport-xs-tab header button {
        overflow: visible;
    }
    .sport-xs-tab>header {
        padding-top: 2px;
    }
    .acceptodds input {
        display: none;
    }
    .acceptodds a:before {
        width: 4.8vw;
        height: 4.8vw;
        background-color: #fff;
        box-shadow: inset 0 0.53333vw 0 0 rgb(0 0 0 / 40%);
        border-radius: 1.06667vw;
        margin-right: 1.86667vw;
        content: "";
    }
    .acceptodds input:checked+a:before {
        background: #FFB867 url(../../static/media/checkef.29ddfe1c.svg) !important;
        background-size: 2.93333vw 2.93333vw !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
    }
    a {
        font-size: unset;
        font-weight: 400;
    }
    .bodypart {
        height: unset;
    }
    .tbl_body .MuiTableCell-root.table_first_row {
        max-width: 180px;
    }
    .mini-games-btn span.MuiButton-label {
        display: block;
        line-height: 24px;
    }
    .user_modal .MuiGrid-grid-xs-6 {
        flex-grow: 0;
        /* max-width: 100%; */
        flex-basis: 100%;
    }
    .mobile-card-tabs .mobile_tab>div>div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .mheaderm>p>a {
        padding: 2px 10px;
    }
    .bottom_nav button img {
        width: 20px;
    }
    button.Bmenubtn span {
        font-size: 10px;
    }
    .bottom_nav button.Bmenubtn {
        padding: 0;
        min-width: unset;
    }
    .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 0 !important;
    }
    .MuiBottomNavigationAction-label.Mui-selected {
        font-size: 10px !important;
    }
    .m-hide {
        display: none;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row {
        display: block;
        margin-bottom: 47px;
    }
    .mini-games-link p {
        margin: 0 10px 0 0;
        font-size: 10px;
        font-style: italic;
    }
    .maintabs button {
        margin: 0 !important;
        padding: 0;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row>div {
        max-width: 100%;
        margin: auto;
    }
    .mini-games-btn::after {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 100%;
        margin-top: 0;
        border-width: 13px;
        border-style: solid;
        border-color: transparent transparent transparent #3e4095 !important;
    }
}

@media (max-width: 1023px) and (min-width: 601px) {
    .maintabs button {
        font-size: 16px !important;
        line-height: 30px !important;
    }
    .mini-games-link p {
        margin: 0 20px 0 0;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row {
        display: block;
    }
    .tl-hide {
        display: none;
    }
    .nav_menu .hamburgerMenu {
        display: block !important;
        min-width: auto !important;
        background: #ffff9c !important;
        box-shadow: 0px 0px 10px 0px #ffffff;
        border-radius: 5px;
        padding: 0;
        margin-right: 10px;
    }
    .nav_menu .hamburgerMenu .MuiButton-root {
        min-width: 40px;
    }
    .tbl_head th p {
        margin: 0;
    }
    .mobile-card-tabs .mobile_tab>div>div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row>div {
        max-width: 95%;
        margin: auto;
    }
    .mini-games-btn::after {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 100%;
        margin-top: 0;
        border-width: 15px;
        border-style: solid;
        border-color: transparent transparent transparent #ed1b23;
    }
}

@media (max-width: 1260px) and (min-width: 1024px) {
    .mobile-card-tabs .mobile_tab>div>div {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 350px) and (min-width: 200px) {
    .header-section .mobile_nav a {
        font-size: 10px;
    }
    .mini-games-link p {
        font-size: 8px;
    }
}

@media (max-width: 600px) and (min-width: 421px) {
    .header-section .mobile_nav a {
        font-size: 12px;
    }
}

@media (max-width: 767px) and (min-width: 601px) {
    .marquee {
        display: none;
    }
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .match-time {
        font-size: 10px;
        color: #878786;
        line-height: initial;
    }
    .match-name {
        font-size: 12px !important;
        color: #3a3a39;
        font-weight: bold;
    }
    .mobile_tab button .MuiGrid-spacing-xs-1 {
        width: 100%;
        margin: auto;
    }
    .app_bar>div .makeStyles-marque-6.MuiTypography-h6, .app_bar>div .makeStyles-marque-7.MuiTypography-h6 {
        display: none;
    }
    .logo-outer, .logo-outer img {
        width: 78% !important;
        max-width: 78% !important;
        line-height: unset !important;
        font-size: unset !important;
    }
    /* HEAD PART */
    .makeStyles-title-3 {
        width: 100%;
        text-align: center;
    }
    .marquee, .wallet, .wallet_icon, .xs_none, .sidenav, .maintabs {
        display: none !important;
    }
    .top_nav img.user_icon_img {
        width: 18px;
    }
    .mobile_nav {
        background: rgb(255, 242, 18);
        background: linear-gradient(90deg, rgba(255, 242, 18, 1) 35%, rgba(204, 193, 60, 1) 100%);
        width: 100%;
        padding: 0;
        text-align: center;
        display: inline-block !important;
        height: 40px;
    }
    .mobile_nav a {
        padding: 0 24px;
        vertical-align: middle;
        display: inline-block;
        font-weight: 900;
        color: #000;
        font-size: 12px;
    }
    .mobile_nav a img {
        width: 25px;
        vertical-align: middle;
    }
    .mobile_news {
        display: inline-block;
        width: 100%;
        background: rgb(62, 64, 149);
        background: linear-gradient(90deg, rgba(62, 64, 149, 1) 33%, rgba(55, 52, 53, 1) 100%);
        color: #ffffff;
    }
    .mobile_news>.mobile_new_block {
        padding: 0 8px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .mobile_news>.mobile_new_block>div {
        display: inline-block;
        font-weight: bold;
        letter-spacing: .6px;
    }
    .mobile_news>.mobile_new_block .first {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        font-size: 12px;
        font-style: italic;
        min-width: 70px;
    }
    .mobile_news>.mobile_new_block .first img {
        width: 14px;
    }
    .mobile_news>.mobile_new_block .second {
        width: 100%;
        font-size: 12px;
        text-align: center;
        vertical-align: top;
        line-height: 17px;
        padding-top: 4px;
        margin-left: 4px;
    }
    .main_tab {
        padding: 0 !important;
    }
    .bottom_nav, .hamburgerMenu, .xs_nav, .main_tab>span>div, .mobile_tab.maintabs {
        display: block !important;
    }
    .xs_tbl_block {
        padding: 8px;
        background: #dfdfdf;
        box-shadow: rgb(0 0 0) 0px 1px 4px;
        margin: 6px 0px;
    }
    .xs_tbl_block>div {
        box-shadow: none !important;
    }
    .xs_tbl_block .xs_tbl_first td {
        padding: 4px !important;
    }
    .xs_tbl_block .tbl_body td {
        margin: 10px;
    }
    .xs_tbl_block .tbl_body {
        background: #dfdfdf;
    }
    .xs_tbl_block .tbl_head {
        background: #fafafa;
    }
    .xs_tbl_block .tbl_head th {
        color: #000 !important;
    }
    .xs_tbl_block .tbl_head th p {
        margin: 0;
        line-height: 1.4;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight {
        background: none !important;
        padding: 0;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight p {
        padding: 8px 0;
        margin: 0 auto;
        border-radius: 16px;
        min-width: 40px;
        max-width: 50px;
        font-size: 14px;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n+1) p {
        background: #7cc4f7;
        font-weight: bold;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n) p {
        background: #fca4b7;
        font-weight: bold;
    }
    /* BODY PART */
    .bodypart {
        margin-top: 104px !important;
        /* margin-bottom: 45px !important; */
    }
    .bodypart-dashboard {
        margin-top: 0px !important;
    }
    .hamburgerIcon {
        min-width: auto !important;
        background: #ffff9c !important;
        box-shadow: 0px 0px 10px 0px #ffffff;
    }
    .MuiGrid-grid-xs-12 {
        /* background: #dfdfdf; */
    }
    /*  */
    .event-single-popup {
        margin-top: 8px !important;
    }
    /* MODAL */
    .xs_modal {
        background: #ffffff;
        width: 98%;
    }
    .modal_btn {
        text-align: center;
    }
    .modal_btn button {
        margin-right: 4px !important;
        margin-left: 8px !important;
    }
    .modal_number {
        text-align: center;
    }
    .modal_number button {
        margin: 2px !important;
        border-radius: 26px;
        background: #1a1a18 !important;
        color: #ffffff;
        box-shadow: 0 0 1px;
        max-width: 50px;
        min-width: 50px;
    }
    .xs_modal .modal_head {
        background-image: #ffff9c;
        padding: 0 8px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }
    .xs_modal .modal_body {
        padding: 12px 8px;
        background: #26221c;
    }
    .xs_modal .modal_body input {
        color: #ffffff;
    }
    .xs_modal .modal_body label {
        color: #ffffff;
    }
    .xs_modal .modal_body .MuiOutlinedInput-notchedOutline {
        border-color: #999999;
    }
    .xs_modal .modal_footer {
        background-image: linear-gradient(to right, #3e3358, #423c51, #333333, #423c51, #3e3358) !important;
        padding: 8px;
    }
    .xs_modal .modal_footer .modal_btn button {
        background: #ffff9c !important;
        font-weight: 900 !important;
        min-width: 60px;
        max-width: 60px;
        margin-left: 4px !important;
        padding: 4px;
        text-transform: none;
        margin-top: 4px !important;
        margin-bottom: 0 !important;
    }
    /* ------------ */
    .search_data {
        float: none;
        margin-top: 8px;
        width: 100%;
    }
    .search_filter {
        display: inline-block;
    }
    .datatable_filter {
        padding: 0 16px 12px
    }
    .search_filter>div, .search_filter>input {
        width: 100%;
        margin-bottom: 8px;
    }
    .datatable_check>div {
        justify-content: space-between;
    }
    .datatable_filter.profit_filter>button, .search_filter>button {
        margin-left: 0;
        margin-right: 12px;
    }
    .datatable_filter.profit_filter {
        padding: 8px 12px 8px
    }
    .datatable_filter.profit_filter>div:first-child, .datatable_filter.bethistory>div, .datatable_filter.livebethistory>div {
        width: 100% !important;
        margin: 0;
        margin-bottom: 8px;
    }
    .datatable_filter.profit_filter>input, .datatable_filter.profit_filter>input[type="text"] {
        margin: 0;
        margin-bottom: 8px;
        width: calc(100% - 12px) !important;
    }
}

@media (max-width: 1260px) and (min-width: 1024px) {
    .txl-grid-2 .MuiGrid-grid-md-4 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
    .tbl_head th p {
        margin: 0;
    }
}

.Bmenubtn img {
    height: 21px;
}

.splide__slide img {
    width: 100%;
}

@media (pointer:coarse) {
    .suspended:after {
        content: attr(data-title);
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 12px !important;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: calc(100% - 230px);
        right: 0;
        background: rgb(139 161 177 / 80%);
        ;
        color: #fff;
        -webkit-backdrop-filter: blur(1px);
                backdrop-filter: blur(1px);
        text-transform: uppercase;
        font-weight: 900;
        font-size: 18px;
        top: 0;
    }
    .desktop-marquee {
        flex-grow: 1;
        width: unset;
    }
    .showtv {
        height: 201px;
    }
    .countdown {
        font-size: 15px;
    }
    .event_image {
        object-fit: fill;
    }
    .footerbig a img {
        width: 10%;
        height: auto;
        vertical-align: middle;
        border-style: none;
    }
    .footer-logo {
        width: 37%;
        display: -webkit-flex;
        justify-content: center;
        align-items: center;
    }
    .footer-social a img {
        width: 7%;
    }
    .splide__slide img {
        width: auto;
    }
    .slider-margin {
        margin-top: 110px !important;
    }
    .bodypart {
        /* margin-top: 111px; */
    }
    .MuiDataGrid-cell {
        word-wrap: break-word !important;
    }
}
.login-body {
    /* background-image: linear-gradient(#fab700,rgba(255,255,255,0.6)), url('./assets/login-bg.jpg'); */
    /* background-image: linear-gradient(#006bff,rgb(255 255 255 / 16%)), url(./assets/login-bg.jpg); */
    background-image: linear-gradient(rgba(255, 184, 12, 0.6), rgba(255, 255, 255, 0.6)), url(../../static/media/login-bg.37b3cdd6.jpg);
    background-size: contain;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 87vh;
    /* max-height: 83vh; */
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    font-family: 'Roboto' , sans-serif;
    font-size: 13px;
}

.login-body .MuiFormHelperText-root {
    position: absolute;
    /* font-size: 15px; */
    margin-top: -14px !important;
    line-height: 1.2 !important;
    text-align: right !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    color: #000 !important;
}

.logoheadImage {
    width: 86%;
    margin: 15px 0;
}

.logo {
    background: black;
    height: 155px;
    justify-content: center;
    display: grid;
    place-items: center;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: auto;
    max-width: 100%;
    vertical-align: middle;
}

.login-margin {
    margin-bottom: 20px !important;
}

#wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 44%;
    width: 332px;
    overflow: hidden;
    /* padding: 20px; */
    border-radius: 0;
    border: none;
    background: #ffffff;
}

.login-form {
    overflow: hidden;
    display: inline-block;
    border: #C0C0C0 solid 0px;
    border-radius: 10px;
}

.login-form form {
    margin: 20px 0;
}

.login-form input {
    padding: 5px 0;
    min-height: 30px;
    font-size: 14px;
    border-bottom: 1px solid #333;
    color: #333;
}

.login-form input:focus, .login-form input:hover, .login-form input:active {
    border-bottom: 1px solid #333 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border: 0 !important;
}

.login-form input::placeholder {
    font-size: 14px;
    color: #000;
}

.login-form .remember-me .MuiTypography-body1 {
    font-size: 14px;
}

.login-form .remember-me .MuiSvgIcon-root {
    font-size: 16px;
}

.login-form .remember-me .PrivateSwitchBase-root-1 {
    padding-right: 8px;
}

.login-btn {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 15px;
    border-radius: 0 !important;
    width: 100%;
    text-transform:capitalize !important;
    margin-bottom: 5px !important;
}
.customfooter{
    background-image: linear-gradient(rgb(111, 111, 111), rgb(15, 15, 15));
}
.parafirst{
    margin-bottom: 0;
    text-align: center;
    padding-top: 10px;
    color:white;
    margin: 0px;
}

 .gamecare {
    width: 80px;
    filter: brightness(0) invert(1);
}
.parafirst a{
    font-weight: 600;
    color: white;
}
.gamstop{
    width: 90px;
}
.plus18{
    width: 28px;
}
.begambleaware{
    width: 115px;
    filter: brightness(0) invert(1);
}
.parasecond{
    margin: 0;
    text-align: center;
    color:white;
    padding-bottom: 5px;
}
.lower-footer{
    color:white;
}
.parasecond img{
    width: 70px;
}
small, .small {
    font-size: 80%;
    font-weight: 400;
}
.top_nav .logo {
    width: 200px;

    height: unset;
    background: unset;
}

