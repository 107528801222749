@media (max-width: 600px) and (min-width: 200px) {
    .typeing:after {
        content: '';
        width: 0.66667vw;
        height: 5.33333vw;
        background-color: #1F72AC;
        animation: typeing 1s infinite;
        box-shadow: none !important;
    }
    /* Bet Slip */
    .xs-bet-slip {
        background-color: #DCEAF4;
        padding-top: 2.66667vw !important;
        border-bottom: 1px solid #7e97a7 !important;
    }
    .bet-list {
        padding: 0 1.6vw 2.66667vw;
        display: flex;
    }
    .bet-list>div {
        width: 100%;
        margin: 1.6vw 0.8vw 0 0.8vw;
        display: flex;
        flex-direction: column;
    }
    .bet-list .typed {
        color: #999;
        background-color: #dcdcdc;
        width: 100%;
        box-shadow: none;
        border: 1px solid #bbb;
        font-size: 4vw;
        border-radius: 1.6vw;
    }
    .bet-list p {
        font-size: 2.93333vw;
        text-align: center;
    }
    .input-num>div {
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        display: flex;
    }
    .input-num>div a {
        height: 10.93333vw;
        line-height: 10.93333vw;
        width: 12vw;
        background-image: linear-gradient(-180deg, #fff 0%, #eee 89%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-num>div span {
        display: flex;
        color: #1e1e1e;
        background-color: #fff0ca;
        box-shadow: inset 0 0.26667vw 1.33333vw rgb(161 128 45 / 60%);
        border-color: #be7809;
        width: 50%;
        align-items: center;
        justify-content: center;
        font-size: 4vw;
        font-weight: bold;
    }
    .input-num>div input {
        display: flex;
        color: #1e1e1e;
        background-color: #fff0ca;
        box-shadow: inset 0 0.26667vw 1.33333vw rgb(161 128 45 / 60%);
        border-color: #be780938;
        width: 50%;
        align-items: center;
        justify-content: center;
        font-size: 4vw;
        font-weight: bold;
        text-align:center;
    }
    .input-num>div a svg {
        font-weight: bold;
        font-size: 28px;
        color: #1f72ac;
    }
    .coin-list {
        margin-bottom: 1.86667vw;
        background-image: linear-gradient(-180deg, #32617F 20%, #1F4258 91%);
        display: flex;
    }
    .coin-list a {
        width: 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.15);
        line-height: 2.46;
        background-image: linear-gradient(-180deg, #32617F 20%, #1F4258 91%);
        color: #fff;
        text-align: center;
    }
    .keyboard-wrap {
        display: flex;
        border-top: 1px solid #aaa;
        margin-bottom: 1.86667vw;
    }
    .keyboard-wrap .btn-tel {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
    }
    .keyboard-wrap .btn-tel a {
        display: flex;
        flex: 1 1 15.66667%;
        font-size: 4vw;
        color: #1e1e1e;
        line-height: 10.4vw;
        background-color: #fff;
        border: 1px solid #aaa;
        border-width: 0 0 1px 1px;
        align-items: center;
        justify-content: center;
    }
    .keyboard-wrap>a {
        display: flex;
        flex: 0 1 14.66667vw;
        color: #1e1e1e;
        line-height: 10.4vw;
        background-color: #fff;
        border: 1px solid #aaa;
        border-width: 0 0 1px 1px;
        align-items: center;
        justify-content: center;
    }
    .btn-list {
        padding: 0 1.6vw 2.66667vw;
        display: flex;
        flex-wrap: wrap;
    }
    .btn-list a {
        flex: 1;
        margin: 1.6vw 0.8vw 0 0.8vw;
        height: 10.93333vw;
        line-height: 10.93333vw;
        background-image: linear-gradient( -180deg, #fff 0%, #eee 89%);
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        font-size: 4vw;
        font-weight: bold;
        line-height: 2.6;
        color: #1e1e1e;
        text-align: center;
    }
    .btn-list-new {
        padding: 0 1.6vw 2.66667vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    
    
    }
    .btn-list-new a {
        margin: 1.6vw 0.8vw 0 0.8vw;
        height: 10.93333vw;
        line-height: 10.93333vw;
        background-image: linear-gradient( -180deg, #fff 0%, #eee 89%);
        border: 1px solid #aaa;
        border-radius: 1.6vw;
        font-size: 4vw;
        font-weight: bold;
        line-height: 2.6;
        color: #1e1e1e;
        text-align: center;
        width: 90%;
    }
    .btn-list a.placebtn {
        background-image: linear-gradient( -180deg, #008000 15%, #008000 100%);
        border-color: #008000;
        color: #000;
        display: table-cell;
    }
    .acceptodds {
        padding: 0 1.86667vw;
        background: #C7DBE9;
        height: 8.53333vw;
        display: flex;
        align-items: center;
        font-size: 3.46667vw;
    }
    .acceptodds label {
        width: 100%;
    }
    .acceptodds a {
        display: flex;
        width: 100%;
        height: 8.53333vw;
        align-items: center;
        font-size: 3.46667vw;
        color: #1e1e1e;
        margin-right: 1.86667vw;
    }
    .MuiTabs-flexContainer {
        padding-top: 0px;
    }
    .sport-xs-tab button.Mui-selected, .sport-xs-tab header button {
        overflow: visible;
    }
    .sport-xs-tab>header {
        padding-top: 2px;
    }
    .acceptodds input {
        display: none;
    }
    .acceptodds a:before {
        width: 4.8vw;
        height: 4.8vw;
        background-color: #fff;
        box-shadow: inset 0 0.53333vw 0 0 rgb(0 0 0 / 40%);
        border-radius: 1.06667vw;
        margin-right: 1.86667vw;
        content: "";
    }
    .acceptodds input:checked+a:before {
        background: #FFB867 url('../assets/checkef.svg') !important;
        background-size: 2.93333vw 2.93333vw !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
    }
    a {
        font-size: unset;
        font-weight: 400;
    }
    .bodypart {
        height: unset;
    }
    .tbl_body .MuiTableCell-root.table_first_row {
        max-width: 180px;
    }
    .mini-games-btn span.MuiButton-label {
        display: block;
        line-height: 24px;
    }
    .user_modal .MuiGrid-grid-xs-6 {
        flex-grow: 0;
        /* max-width: 100%; */
        flex-basis: 100%;
    }
    .mobile-card-tabs .mobile_tab>div>div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .mheaderm>p>a {
        padding: 2px 10px;
    }
    .bottom_nav button img {
        width: 20px;
    }
    button.Bmenubtn span {
        font-size: 10px;
    }
    .bottom_nav button.Bmenubtn {
        padding: 0;
        min-width: unset;
    }
    .MuiGrid-spacing-xs-2>.MuiGrid-item {
        padding: 0 !important;
    }
    .MuiBottomNavigationAction-label.Mui-selected {
        font-size: 10px !important;
    }
    .m-hide {
        display: none;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row {
        display: block;
        margin-bottom: 47px;
    }
    .mini-games-link p {
        margin: 0 10px 0 0;
        font-size: 10px;
        font-style: italic;
    }
    .maintabs button {
        margin: 0 !important;
        padding: 0;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row>div {
        max-width: 100%;
        margin: auto;
    }
    .mini-games-btn::after {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 100%;
        margin-top: 0;
        border-width: 13px;
        border-style: solid;
        border-color: transparent transparent transparent #3e4095 !important;
    }
}

@media (max-width: 1023px) and (min-width: 601px) {
    .maintabs button {
        font-size: 16px !important;
        line-height: 30px !important;
    }
    .mini-games-link p {
        margin: 0 20px 0 0;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row {
        display: block;
    }
    .tl-hide {
        display: none;
    }
    .nav_menu .hamburgerMenu {
        display: block !important;
        min-width: auto !important;
        background: #ffff9c !important;
        box-shadow: 0px 0px 10px 0px #ffffff;
        border-radius: 5px;
        padding: 0;
        margin-right: 10px;
    }
    .nav_menu .hamburgerMenu .MuiButton-root {
        min-width: 40px;
    }
    .tbl_head th p {
        margin: 0;
    }
    .mobile-card-tabs .mobile_tab>div>div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .MuiGrid-root.bodypart.custom-container.grid-row>div {
        max-width: 95%;
        margin: auto;
    }
    .mini-games-btn::after {
        content: " ";
        position: absolute;
        top: 0;
        bottom: 0px;
        left: 100%;
        margin-top: 0;
        border-width: 15px;
        border-style: solid;
        border-color: transparent transparent transparent #ed1b23;
    }
}

@media (max-width: 1260px) and (min-width: 1024px) {
    .mobile-card-tabs .mobile_tab>div>div {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 350px) and (min-width: 200px) {
    .header-section .mobile_nav a {
        font-size: 10px;
    }
    .mini-games-link p {
        font-size: 8px;
    }
}

@media (max-width: 600px) and (min-width: 421px) {
    .header-section .mobile_nav a {
        font-size: 12px;
    }
}

@media (max-width: 767px) and (min-width: 601px) {
    .marquee {
        display: none;
    }
}

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .match-time {
        font-size: 10px;
        color: #878786;
        line-height: initial;
    }
    .match-name {
        font-size: 12px !important;
        color: #3a3a39;
        font-weight: bold;
    }
    .mobile_tab button .MuiGrid-spacing-xs-1 {
        width: 100%;
        margin: auto;
    }
    .app_bar>div .makeStyles-marque-6.MuiTypography-h6, .app_bar>div .makeStyles-marque-7.MuiTypography-h6 {
        display: none;
    }
    .logo-outer, .logo-outer img {
        width: 78% !important;
        max-width: 78% !important;
        line-height: unset !important;
        font-size: unset !important;
    }
    /* HEAD PART */
    .makeStyles-title-3 {
        width: 100%;
        text-align: center;
    }
    .marquee, .wallet, .wallet_icon, .xs_none, .sidenav, .maintabs {
        display: none !important;
    }
    .top_nav img.user_icon_img {
        width: 18px;
    }
    .mobile_nav {
        background: rgb(255, 242, 18);
        background: linear-gradient(90deg, rgba(255, 242, 18, 1) 35%, rgba(204, 193, 60, 1) 100%);
        width: 100%;
        padding: 0;
        text-align: center;
        display: inline-block !important;
        height: 40px;
    }
    .mobile_nav a {
        padding: 0 24px;
        vertical-align: middle;
        display: inline-block;
        font-weight: 900;
        color: #000;
        font-size: 12px;
    }
    .mobile_nav a img {
        width: 25px;
        vertical-align: middle;
    }
    .mobile_news {
        display: inline-block;
        width: 100%;
        background: rgb(62, 64, 149);
        background: linear-gradient(90deg, rgba(62, 64, 149, 1) 33%, rgba(55, 52, 53, 1) 100%);
        color: #ffffff;
    }
    .mobile_news>.mobile_new_block {
        padding: 0 8px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        overflow: hidden;
    }
    .mobile_news>.mobile_new_block>div {
        display: inline-block;
        font-weight: bold;
        letter-spacing: .6px;
    }
    .mobile_news>.mobile_new_block .first {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        font-size: 12px;
        font-style: italic;
        min-width: 70px;
    }
    .mobile_news>.mobile_new_block .first img {
        width: 14px;
    }
    .mobile_news>.mobile_new_block .second {
        width: 100%;
        font-size: 12px;
        text-align: center;
        vertical-align: top;
        line-height: 17px;
        padding-top: 4px;
        margin-left: 4px;
    }
    .main_tab {
        padding: 0 !important;
    }
    .bottom_nav, .hamburgerMenu, .xs_nav, .main_tab>span>div, .mobile_tab.maintabs {
        display: block !important;
    }
    .xs_tbl_block {
        padding: 8px;
        background: #dfdfdf;
        box-shadow: rgb(0 0 0) 0px 1px 4px;
        margin: 6px 0px;
    }
    .xs_tbl_block>div {
        box-shadow: none !important;
    }
    .xs_tbl_block .xs_tbl_first td {
        padding: 4px !important;
    }
    .xs_tbl_block .tbl_body td {
        margin: 10px;
    }
    .xs_tbl_block .tbl_body {
        background: #dfdfdf;
    }
    .xs_tbl_block .tbl_head {
        background: #fafafa;
    }
    .xs_tbl_block .tbl_head th {
        color: #000 !important;
    }
    .xs_tbl_block .tbl_head th p {
        margin: 0;
        line-height: 1.4;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight {
        background: none !important;
        padding: 0;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight p {
        padding: 8px 0;
        margin: 0 auto;
        border-radius: 16px;
        min-width: 40px;
        max-width: 50px;
        font-size: 14px;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n+1) p {
        background: #7cc4f7;
        font-weight: bold;
    }
    .xs_tbl_block .odd_even_clr .MuiTableCell-root.MuiTableCell-alignRight:nth-child(2n) p {
        background: #fca4b7;
        font-weight: bold;
    }
    /* BODY PART */
    .bodypart {
        margin-top: 104px !important;
        /* margin-bottom: 45px !important; */
    }
    .bodypart-dashboard {
        margin-top: 0px !important;
    }
    .hamburgerIcon {
        min-width: auto !important;
        background: #ffff9c !important;
        box-shadow: 0px 0px 10px 0px #ffffff;
    }
    .MuiGrid-grid-xs-12 {
        /* background: #dfdfdf; */
    }
    /*  */
    .event-single-popup {
        margin-top: 8px !important;
    }
    /* MODAL */
    .xs_modal {
        background: #ffffff;
        width: 98%;
    }
    .modal_btn {
        text-align: center;
    }
    .modal_btn button {
        margin-right: 4px !important;
        margin-left: 8px !important;
    }
    .modal_number {
        text-align: center;
    }
    .modal_number button {
        margin: 2px !important;
        border-radius: 26px;
        background: #1a1a18 !important;
        color: #ffffff;
        box-shadow: 0 0 1px;
        max-width: 50px;
        min-width: 50px;
    }
    .xs_modal .modal_head {
        background-image: #ffff9c;
        padding: 0 8px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
    }
    .xs_modal .modal_body {
        padding: 12px 8px;
        background: #26221c;
    }
    .xs_modal .modal_body input {
        color: #ffffff;
    }
    .xs_modal .modal_body label {
        color: #ffffff;
    }
    .xs_modal .modal_body .MuiOutlinedInput-notchedOutline {
        border-color: #999999;
    }
    .xs_modal .modal_footer {
        background-image: linear-gradient(to right, #3e3358, #423c51, #333333, #423c51, #3e3358) !important;
        padding: 8px;
    }
    .xs_modal .modal_footer .modal_btn button {
        background: #ffff9c !important;
        font-weight: 900 !important;
        min-width: 60px;
        max-width: 60px;
        margin-left: 4px !important;
        padding: 4px;
        text-transform: none;
        margin-top: 4px !important;
        margin-bottom: 0 !important;
    }
    /* ------------ */
    .search_data {
        float: none;
        margin-top: 8px;
        width: 100%;
    }
    .search_filter {
        display: inline-block;
    }
    .datatable_filter {
        padding: 0 16px 12px
    }
    .search_filter>div, .search_filter>input {
        width: 100%;
        margin-bottom: 8px;
    }
    .datatable_check>div {
        justify-content: space-between;
    }
    .datatable_filter.profit_filter>button, .search_filter>button {
        margin-left: 0;
        margin-right: 12px;
    }
    .datatable_filter.profit_filter {
        padding: 8px 12px 8px
    }
    .datatable_filter.profit_filter>div:first-child, .datatable_filter.bethistory>div, .datatable_filter.livebethistory>div {
        width: 100% !important;
        margin: 0;
        margin-bottom: 8px;
    }
    .datatable_filter.profit_filter>input, .datatable_filter.profit_filter>input[type="text"] {
        margin: 0;
        margin-bottom: 8px;
        width: calc(100% - 12px) !important;
    }
}

@media (max-width: 1260px) and (min-width: 1024px) {
    .txl-grid-2 .MuiGrid-grid-md-4 {
        flex-grow: 0;
        max-width: 50%;
        flex-basis: 50%;
    }
    .tbl_head th p {
        margin: 0;
    }
}

.Bmenubtn img {
    height: 21px;
}

.splide__slide img {
    width: 100%;
}

@media (pointer:coarse) {
    .suspended:after {
        content: attr(data-title);
        font-family: 'Roboto Condensed', sans-serif;
        font-size: 12px !important;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: calc(100% - 230px);
        right: 0;
        background: rgb(139 161 177 / 80%);
        ;
        color: #fff;
        backdrop-filter: blur(1px);
        text-transform: uppercase;
        font-weight: 900;
        font-size: 18px;
        top: 0;
    }
    .desktop-marquee {
        flex-grow: 1;
        width: unset;
    }
    .showtv {
        height: 201px;
    }
    .countdown {
        font-size: 15px;
    }
    .event_image {
        object-fit: fill;
    }
    .footerbig a img {
        width: 10%;
        height: auto;
        vertical-align: middle;
        border-style: none;
    }
    .footer-logo {
        width: 37%;
        display: -webkit-flex;
        justify-content: center;
        align-items: center;
    }
    .footer-social a img {
        width: 7%;
    }
    .splide__slide img {
        width: auto;
    }
    .slider-margin {
        margin-top: 110px !important;
    }
    .bodypart {
        /* margin-top: 111px; */
    }
    .MuiDataGrid-cell {
        word-wrap: break-word !important;
    }
}