@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.login-body {
    /* background-image: linear-gradient(#fab700,rgba(255,255,255,0.6)), url('./assets/login-bg.jpg'); */
    /* background-image: linear-gradient(#006bff,rgb(255 255 255 / 16%)), url(./assets/login-bg.jpg); */
    background-image: linear-gradient(rgba(255, 184, 12, 0.6), rgba(255, 255, 255, 0.6)), url(./assets/login-bg.jpg);
    background-size: contain;
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 87vh;
    /* max-height: 83vh; */
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    font-family: 'Roboto' , sans-serif;
    font-size: 13px;
}

.login-body .MuiFormHelperText-root {
    position: absolute;
    /* font-size: 15px; */
    margin-top: -14px !important;
    line-height: 1.2 !important;
    text-align: right !important;
    font-size: 1rem !important;
    font-weight: bold !important;
    color: #000 !important;
}

.logoheadImage {
    width: 86%;
    margin: 15px 0;
}

.logo {
    background: black;
    height: 155px;
    justify-content: center;
    display: grid;
    place-items: center;
}

a {
    text-decoration: none;
    color: unset;
}

img {
    width: auto;
    max-width: 100%;
    vertical-align: middle;
}

.login-margin {
    margin-bottom: 20px !important;
}

#wrapper {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 44%;
    width: 332px;
    overflow: hidden;
    /* padding: 20px; */
    border-radius: 0;
    border: none;
    background: #ffffff;
}

.login-form {
    overflow: hidden;
    display: inline-block;
    border: #C0C0C0 solid 0px;
    border-radius: 10px;
}

.login-form form {
    margin: 20px 0;
}

.login-form input {
    padding: 5px 0;
    min-height: 30px;
    font-size: 14px;
    border-bottom: 1px solid #333;
    color: #333;
}

.login-form input:focus, .login-form input:hover, .login-form input:active {
    border-bottom: 1px solid #333 !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
    border: 0 !important;
}

.login-form input::placeholder {
    font-size: 14px;
    color: #000;
}

.login-form .remember-me .MuiTypography-body1 {
    font-size: 14px;
}

.login-form .remember-me .MuiSvgIcon-root {
    font-size: 16px;
}

.login-form .remember-me .PrivateSwitchBase-root-1 {
    padding-right: 8px;
}

.login-btn {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 15px;
    border-radius: 0 !important;
    width: 100%;
    text-transform:capitalize !important;
    margin-bottom: 5px !important;
}
.customfooter{
    background-image: linear-gradient(rgb(111, 111, 111), rgb(15, 15, 15));
}
.parafirst{
    margin-bottom: 0;
    text-align: center;
    padding-top: 10px;
    color:white;
    margin: 0px;
}

 .gamecare {
    width: 80px;
    filter: brightness(0) invert(1);
}
.parafirst a{
    font-weight: 600;
    color: white;
}
.gamstop{
    width: 90px;
}
.plus18{
    width: 28px;
}
.begambleaware{
    width: 115px;
    filter: brightness(0) invert(1);
}
.parasecond{
    margin: 0;
    text-align: center;
    color:white;
    padding-bottom: 5px;
}
.lower-footer{
    color:white;
}
.parasecond img{
    width: 70px;
}
small, .small {
    font-size: 80%;
    font-weight: 400;
}
.top_nav .logo {
    width: 200px;

    height: unset;
    background: unset;
}
